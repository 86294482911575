<template>
  <!-- 客户归属 -->
  <div class="wrapper">
<!--    <div class="item">-->
<!--      <el-select v-model="userId" placeholder="请选择" size="small" @change="handleChange">-->
<!--        <el-option v-for="item in userList" :key="item.id" :label="item.userName" :value="item.id"> </el-option>-->
<!--      </el-select>-->
<!--    </div>-->
    <div class="item lock">
      <span style="margin-right:10px">监管锁</span>
      <el-select v-model="abm" :disabled="is_abm" placeholder="请选择" size="small" @change="handleChangeAbm">
        <el-option label="是" :value="true"> </el-option>
        <el-option label="否" :value="false"> </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { getKfOrderDrawAPI, getUserListAPI, handleEnableAbmAPI } from './api'
export default {
  name: 'SelectCustomer',
  props: ['kfUserId', 'flag', 'enableAbm', 'status'],
  data() {
    return {
      userId: '',
      userList: [],
      abm: ''
    }
  },
  computed: {
    is_abm() {
      let disabled = true
      if (this.status == 1 || this.status == 2 || this.status == 3 || this.status == 4) {
        disabled = false
      }
      return disabled
    }
  },
  watch: {
    kfUserId: {
      handler(newV, oldV) {
        this.userId = newV
        const res = this.userList.some(item => item.id == this.userId)
        if (!res) {
          this.userId = null
        }
      }
    },
    enableAbm: {
      // immediate: true,
      handler(newV, oldV) {
        this.abm = newV
      }
    }
  },
  mounted() {
    this.getUserList()
  },

  methods: {
    // 是否监管
    handleChangeAbm() {
      // const name = this.userList.find(item => item.id == this.userId).userName
      const data = { enableAbm: this.abm, orderNo: this.$route.params.id }
      this.$confirm(`您确定修改吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handleEnableAbmAPI(data)
            .then(() => {
              this.$emit('update:flag', false)
              this.$emit('updateDetail')
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            })
            .catch(() => {
              this.abm = this.enableAbm
            })
        })
        .catch(() => {
          this.abm = this.enableAbm
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 分配客服
    handleChange() {
      const name = this.userList.find(item => item.id == this.userId).userName
      const data = { userId: this.userId, orderNo: this.$route.params.id }
      this.$confirm(`您确定客户归属为${name}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getKfOrderDrawAPI(data).then(() => {
            this.$emit('update:flag', false)
            this.$emit('updateDetail')
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.userId = this.kfUserId || null
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 客服列表
    async getUserList() {
      const res = await getUserListAPI()
      this.userList = res
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  background: #fafafa;
  padding: 15px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  .item {
    margin-right: 300px;
  }
}
</style>
