<template>
  <div class="wrapper">
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="3" border>
      <el-descriptions-item label="工作单位" label-class-name="my-label">{{jobData.workCompany}}</el-descriptions-item>
      <el-descriptions-item label="岗位" label-class-name="my-label">
        <span>{{jobData.jobCategoryName}}</span>
        <span style="margin-left: 10px;">岗位备注：{{jobData.jobName}}</span>
      </el-descriptions-item>
      <el-descriptions-item label="薪资" label-class-name="my-label">{{jobData.income}}</el-descriptions-item>
      <el-descriptions-item label="芝麻分" label-class-name="my-label">{{jobData.zmScore}}</el-descriptions-item>
      <el-descriptions-item label="在租详情" :span="2" label-class-name="my-label" content-class-name="my-content">
        {{jobData.rentDetail}}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: 'Job',
  props: {
    jobInfo: {
      type: Object,
      default: () => {}
    },
    member: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      jobData: {
        memberId: '',
        workCompany: '',
        jobCategoryName: '',
        jobName: '',
        income: '',
        zmScore: '',
        rentDetail: ''
      }
    }
  },
  watch: {
    jobInfo: {
      handler(newV, oldV) {
        if (newV && this.flag) this.jobData = this.jobInfo
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .job-form {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
