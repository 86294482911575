<template>
  <!-- 三方 -->
  <div class="wrapper">
    <div class="tabs-box">
      <div :class="['item', currentIndex == 0 ? 'active' : '']" @click="handleClick(0)">百融&优分</div>
      <div :class="['item', currentIndex == 1 ? 'active' : '']" @click="handleClick(1)">全景雷达</div>
      <div :class="['item', currentIndex == 2 ? 'active' : '']" @click="handleClick(2)">探针报告</div>
    </div>
    <BaiRong v-if="currentIndex == 0" :info="riskInfo"></BaiRong>
    <XinyanSpray v-if="currentIndex == 1" :info="riskInfo.xinYanSpray"></XinyanSpray>
    <XinyanProbe v-if="currentIndex == 2" :probe="riskInfo.xinYanProbe" :document="riskInfo.xinYanDocument"></XinyanProbe>
  </div>
</template>

<script>
import BaiRong from './risk-modules/bairong.vue'
import XinyanSpray from './risk-modules/xinyan-spray.vue'
import XinyanProbe from './risk-modules/xinyan-probe.vue'
import { getOrderRiskAPI } from './api'
export default {
  name: 'DetailRisk',
  components: { BaiRong, XinyanSpray, XinyanProbe },
  data() {
    return {
      currentIndex: 0,
      riskInfo: {}
    }
  },

  mounted() {
    this.getOrderRisk()
  },

  methods: {
    handleClick(i) {
      this.currentIndex = i
    },
    async getOrderRisk() {
      const res = await getOrderRiskAPI(this.$route.query.memberId)
      this.riskInfo = res
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .tabs-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .item {
      cursor: pointer;
      margin-right: 20px;
      background-color: #cecece;
      padding: 4px 6px;
      border-radius: 3px;
    }
    .active {
      background-color: #93d2f3;
    }
  }
}
</style>
