<template>
  <div class="wrapper">
    <el-descriptions style="margin-top:20px;margin-bottom:20px;" direction="vertical" :column="5" border>
      <el-descriptions-item label="系统评分" :span="1" label-class-name="my-label">
        {{ scoreProfileData.sysHitScore >= 0 ? scoreProfileData.sysHitScore + '分' : '' }}
        <el-button v-if="scoreProfileData.sysHitScore >= 0" style="margin-left:10px" type="primary" size="small" @click="scoreBillList">查看更多</el-button>
      </el-descriptions-item>
      <el-descriptions-item label="系统评级" :span="1" label-class-name="my-label">{{ scoreProfileData.sysHitGrade }}</el-descriptions-item>
      <el-descriptions-item label="最终评级" :span="1" label-class-name="my-label">{{ scoreProfileData.finalHitGrade }}</el-descriptions-item>
      <el-descriptions-item label="审核建议" :span="2" label-class-name="my-label">{{ scoreProfileData.sysHitSuggestDesc }}</el-descriptions-item>
      <el-descriptions-item label="芝麻分" :span="1" label-class-name="my-label">{{ scoreProfileData.zmScore }}</el-descriptions-item>
      <el-descriptions-item label="信用情况" :span="1" label-class-name="my-label">{{ scoreProfileData.creditStatusDesc }}</el-descriptions-item>
      <el-descriptions-item label="租机历史" :span="1" label-class-name="my-label">{{ scoreProfileData.rentHistoryDesc }}</el-descriptions-item>
      <el-descriptions-item label="涉诉情况" :span="1" label-class-name="my-label">{{ scoreProfileData.involveStatusDesc }}</el-descriptions-item>
      <el-descriptions-item label="是否在读学生" :span="1" label-class-name="my-label">{{ scoreProfileData.inSchoolStatusDesc }}</el-descriptions-item>
      <el-descriptions-item label="工作单位" :span="2" label-class-name="my-label">{{ scoreProfileData.workCompany }}</el-descriptions-item>
      <el-descriptions-item label="岗位" :span="2" label-class-name="my-label">
        <span>{{ scoreProfileData.jobCategoryName }}</span>
<!--        <span style="margin-left: 10px;">岗位备注：{{ scoreProfileData.jobName || '' }}</span>-->
      </el-descriptions-item>
      <el-descriptions-item label="薪资" label-class-name="my-label">{{ scoreProfileData.income }}</el-descriptions-item>
    </el-descriptions>

    <el-dialog title="系统评分详情" :visible.sync="scoreBillInfoDialog" width="800px">
      <div class="score-bill-title">
        <span>系统等级：{{ scoreProfileData.sysHitGrade }}</span>
        <span>系统评分：{{ scoreProfileData.sysHitScore }}</span>
        <span>系统建议：{{ scoreProfileData.sysHitSuggestDesc }}</span>
      </div>
      <div class="score-bill-table">
        <tp-table :showPagination="false" :isNeedSerialNumber="true" :tableData="scoreBillInfo.billList" :columns="columns" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getScoreBillInfoAPI } from './api'

const columns = [
  {
    label: '评分项命中规则',
    prop: 'ruleName',
    minWidth: '300',
    customRender(h, row) {
      return <p>{row['ruleName']}</p>
    }
  },
  {
    label: '分值',
    prop: 'hitScore',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['hitScore']}</p>
    }
  },
  {
    label: '审核建议',
    prop: 'hitSuggestDesc',
    minWidth: '100',
    customRender(h, row) {
      return <div>{row['hitSuggestDesc']}</div>
    }
  },
]

export default {
  name: 'ScoreProfile',
  props: {
    scoreProfileInfo: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columns,
      scoreProfileData: {
        orderNo: '',
        sysHitScore: '',
        sysHitSuggestDesc: '',
        sysHitGrade: '',
        finalHitGrade: '',
        zmScore: '',
        creditStatusDesc: '',
        rentHistoryDesc: '',
        involveStatusDesc: '',
        inSchoolStatusDesc: '',
        workCompany: '',
        jobCategoryName: '',
        jobName: '',
        income: '',
      },
      scoreBillInfoDialog: false,
      scoreBillInfo: {},
    }
  },
  watch: {
    scoreProfileInfo: {
      immediate: true,
      handler(newV, oldV) {
        if (newV && this.flag) this.scoreProfileData = this.scoreProfileInfo
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},

  methods: {
    scoreBillList() {
      getScoreBillInfoAPI({orderNo: this.scoreProfileData.orderNo}).then(res => {
        this.scoreBillInfoDialog = true
        this.scoreBillInfo = res
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .el-descriptions-item__cell {
    height: 50px;
  }
  .score-bill-title {
    & > span {
      margin-right: 50px;
      font-weight: 600;
    }
  }
  .score-bill-table {
    margin-top: 20px;
    .tp-table {
      .el-table__fixed-right {
        height: 100% !important;
      }
    }
  }
}
</style>
