<template>
  <div class="wrapper">
    <div class="rule" v-for="(item, i) in list" :key="i">
      <div class="content">
        <div class="title" v-if="item.ruleType == 2">
          命中<span style="color: #67c23a">弱</span>规则<span>{{ item.ruleName }}：</span>
        </div>
        <div class="title" v-if="item.ruleType == 3">
          命中<span style="color: #f56c6c">强</span>规则<span>{{ item.ruleName }}：</span>
        </div>
        <div class="list">
          <div class="row" v-for="(rule, index) in item.hitDataList" :key="index">
            <span style="white-space: pre-wrap;" v-html="rule"></span>
          </div>
        </div>
      </div>
    </div>
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { getRiskReviewAPI } from './api'
export default {
  name: 'RiskReview',

  data() {
    return {
      list: []
    }
  },

  mounted() {
    this.getRiskReview()
  },
  filters: {
    handleRuleType(val) {
      let str = ''
      if (val == 1) {
        str = '前置'
      } else if (val == 2) {
        str = '弱'
      } else if (val == 3) {
        str = '强'
      }
      return str
    }
  },
  methods: {
    async getRiskReview() {
      this.list = await getRiskReviewAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  .rule {
    margin-bottom: 10px;

    .content {
      display: flex;
      .title {
        font-weight: 400;
        margin: 0;

        span {
          font-weight: 700;
        }
      }
      .list {
        .row {
          background-color: #f5f5f5;
          margin-bottom: 10px;
          padding: 0 10px;
          border-radius: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
