<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-tabs v-model="activeName">
      <el-tab-pane label="基础信息" name="1"></el-tab-pane>
      <el-tab-pane label="机审信息" name="2"></el-tab-pane>
      <el-tab-pane label="三方数据" name="3"></el-tab-pane>
    </el-tabs>
    <!-- 基础信息 -->
    <DetailBase ref="base" v-show="activeName == 1"></DetailBase>
    <!-- 机审信息 -->
    <SetailRiskReview ref="riskReview" v-show="activeName == 2"></SetailRiskReview>
    <!-- 三方数据 -->
    <DetailRisk ref="risk" v-show="activeName == 3"></DetailRisk>
  </div>
</template>

<script>
import DetailBase from './detail-base.vue'
import DetailRisk from './detail-risk.vue'
import SetailRiskReview from './detail-risk-review.vue'
export default {
  name: 'Detail',
  components: { DetailBase, DetailRisk, SetailRiskReview },
  data() {
    return {
      activeName: '1'
    }
  },
  beforeRouteLeave(to, from, next) {
    // ...
    if (to.name == 'OrderUpdateGoods') {
      this.$refs.base.flag = false
    }
    next()
  },
  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .title {
    font-weight: 600;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
