<template>
  <div class="wrapper">
    <el-checkbox disabled v-for="item in orderBillList" :key="item.billNum" :label="item" v-model="item.isPrepay"
      >第{{ item.billNum }}期（￥：{{ item.billPrice }}）</el-checkbox>
  </div>
</template>

<script>
export default {
  name: 'Period',
  props: ['orderBillList'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  background: #fafafa;
  padding: 15px;
  .el-checkbox__input.is-disabled.is-checked {
    .el-checkbox__inner {
      background-color: #66b1ff;
      border-color: #66b1ff;
      &::after {
        border-color: #fafafa;
      }
    }
  }
  .el-checkbox.is-disabled {
    .el-checkbox__label {
      color: #66b1ff;
    }
  }
}
</style>
