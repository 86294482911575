<template>
  <!-- 基础信息 -->
  <div class="base-info-wrapper">
    <!-- 商品信息 -->
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="10" border>
      <el-descriptions-item label="订单号" :span="2" label-class-name="my-label">
        {{ $route.params.id }}
        <el-tag type="primary" v-if="detail.orderType == 1" size="mini">普通订单 </el-tag>
        <el-tag type="warning" v-if="detail.orderType == 2" size="mini">门店订单 </el-tag>
        <el-tag type="success" v-if="detail.orderType == 3" size="mini">卡券订单 </el-tag>
        <el-tag type="info" size="mini" style="margin-left: 5px;">{{ detail.statusName }}</el-tag>
        <el-tag type="danger" v-if="detail.enableAlipayFundAuth" size="mini" style="margin-left: 5px">免押</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="商品描述" :span="3" label-class-name="my-label">
        <template v-if="detail.orderGoods">
          <div class="handle">
            <span>
              {{ detail.orderGoods.skuTitle }}
              <el-tag :type="detail.orderGoods.goodsPurityName == '全新' ? 'primary' : 'warning'" size="mini">
                {{ detail.orderGoods.goodsPurityName }}
              </el-tag>
            </span>
            <span>
              <el-button
                v-show="detail.status == 1 && isShowBtn(AUTH_BTN.order_list_update) && detail.orderType != 2"
                size="mini"
                type="primary"
                @click="
                  $router.push({
                    name: 'OrderUpdateGoods',
                    params: { id: $route.params.id },
                    query: { memberId: $route.query.memberId }
                  })
                "
                >修改</el-button
              >
            </span>
          </div>
        </template>
      </el-descriptions-item>
      <el-descriptions-item label="门店信息" :span="3" label-class-name="my-label">
        <div v-if="detail.store">
          {{ detail.store.storeName }}
          <el-tag :key="item" v-for="item in detail.store.storeTagList" type="danger" style="margin-left: 5px;" size="mini">{{
            item
          }}</el-tag>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="监管锁费" :span="1" label-class-name="my-label">{{
        detail.store ? detail.store.lockAmount : ''
      }}</el-descriptions-item>
      <el-descriptions-item label="下单时间" :span="1" label-class-name="my-label">{{ detail.createTime }}</el-descriptions-item>
      <!-- 第二排 -->
      <el-descriptions-item label="费率" :span="1" label-class-name="my-label"> {{ detail.priceRate }} % </el-descriptions-item>
      <el-descriptions-item label="零售价" :span="1" label-class-name="my-label">
        <div class="handle">
          <span> {{ detail.salePrice }}</span>
          <span>
            <el-button
              v-show="
                isShowBtn(AUTH_BTN.order_list_detail_update_sale_price) && detail.goodsCombo == 'payAtOnce' && detail.status == 2
              "
              size="mini"
              type="primary"
              @click="updatePrice"
              >修改</el-button
            >
          </span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="首付租金" :span="2" label-class-name="my-label">
        {{ detail.prepayRentPrice }}
        <el-tag type="primary" v-if="detail.prepayPayMode == 'member:offline'" size="mini">线下支付 </el-tag>
        <el-tag type="success" v-if="detail.prepayPayMode != '' && detail.prepayPayMode != 'member:offline'" size="mini">
          线上支付
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="租期" :span="1" label-class-name="my-label"
        >{{ detail.billPeriodNum }} 期
      </el-descriptions-item>
      <el-descriptions-item label="总租金" :span="1" label-class-name="my-label">{{ detail.rentPrice }}</el-descriptions-item>
      <el-descriptions-item label="保险金" :span="1" label-class-name="my-label">{{
        detail.insurancePrice
      }}</el-descriptions-item>
      <el-descriptions-item label="平台服务费" :span="1" label-class-name="my-label">{{
        detail.servicePrice
      }}</el-descriptions-item>
      <el-descriptions-item label="买断金" :span="1" label-class-name="my-label">{{ detail.buyoutPrice }}</el-descriptions-item>
      <el-descriptions-item label="门店结算金额" :span="1" label-class-name="my-label">
        <el-button
          :disabled="detail.orderType != 2 || !isShowBtn(AUTH_BTN.order_store_calc)"
          type="primary"
          size="mini"
          @click="startCalc"
          >开始计算</el-button
        >
      </el-descriptions-item>
    </el-descriptions>
    <!-- 零售价 -->
    <el-dialog title="修改零售价" :visible.sync="periodDialog" width="800px">
      <el-form ref="periodForm" :model="priceData" label-width="80px">
        <el-form-item label="新零售价">
          <el-input-number
            style="width:200px"
            v-model="priceData.salePrice"
            size="small"
            :precision="2"
            :min="0"
            :step="1"
            step-strictly
          ></el-input-number>
          <!-- <span style="margin-left:5px;font-size:16px">%</span> -->
          <!-- <span class="tips">*输入区间：15-50</span> -->
        </el-form-item>
        <!-- <el-form-item label="">
          <el-button @click="servicePriceRateDialog = false" size="small">取 消</el-button>
          <el-button type="primary" @click="saveServicePriceRate" size="small">确 定</el-button>
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button @click="periodDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="savePrice" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 门店金额结算 -->
    <el-dialog class="store-dialog" title="门店待结算金额" :visible.sync="show_store_calc" width="800px">
      <el-form class="store-box" ref="storeForm" :model="storeData" label-width="140px">
        <el-form-item label="订单号：">
          {{ this.$route.params.id }}
        </el-form-item>
        <el-form-item label="商品信息：">
          {{ calc_result.goodsTitle }}
        </el-form-item>
        <el-form-item label="商品进货价："> ￥{{ calc_result.costAmount }} </el-form-item>
        <el-form-item label="客户首付款：">
          <div class="row-box">
            <span> ￥{{ calc_result.prepayAmount }}</span>
            <span>
              <el-select v-model="storeData.prepayPayeeType" size="mini" placeholder="请选择">
                <el-option label="平台收取" :value="1"> </el-option>
                <el-option label="门店收取" :value="2"> </el-option>
              </el-select>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="监管锁费用：">
          <div class="row-box">
            <span> ￥{{ calc_result.lockAmount }}</span>
            <span>
              <el-select v-model="storeData.lockPayeeType" size="mini" placeholder="请选择">
                <el-option label="平台收取" :value="1"> </el-option>
                <el-option label="门店收取" :value="2"> </el-option>
              </el-select>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="订单押金：">
          <div class="row-box">
            <span>
              <el-input-number
                v-model="storeData.depositAmount"
                size="mini"
                :precision="2"
                :step="0.01"
                step-strictly
                :min="0"
              ></el-input-number>
            </span>
            <span>
              <el-select v-model="storeData.depositPayeeType" size="mini" placeholder="请选择">
                <el-option label="平台收取" :value="1"> </el-option>
                <el-option label="门店收取" :value="2"> </el-option>
              </el-select>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="门店待结算金额：">
          <span class="calc-money"> ￥ {{ calc_result.settleAmount }} </span>
        </el-form-item>
        <el-form-item label="" label-width="0">
          <div style="text-align: center;padding-top: 20px">
            <el-button type="primary" size="medium" @click="submitCalc">计 算 金 额</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { updatePriceAPI, calcStoreSettleAPI } from './api'
export default {
  name: 'BaseInfo',
  mixins: [authBtnMixin],
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show_store_calc: false,
      periodDialog: false,
      priceData: {
        salePrice: '',
        orderNo: ''
      },
      storeData: {
        orderNo: this.$route.params.id,
        prepayPayeeType: 1,
        lockPayeeType: 1,
        depositAmount: '',
        depositPayeeType: 1
      },
      calc_result: {
        orderNo: '',
        goodsTitle: '',
        costAmount: '',
        prepayAmount: '',
        prepayPayeeType: 1,
        lockAmount: '',
        lockPayeeType: 1,
        depositAmount: '',
        depositPayeeType: 1,
        settleAmount: ''
      },
      storeCalcMoney: 0
    }
  },
  computed: {
    // 零售价显示，已废弃
    is_show() {
      const { status } = this.detail
      const status_arr = [2, 5, 6, 7, 91, 92, 93]
      const res = status_arr.indexOf(status)
      return res == -1 ? false : true
    }
  },
  mounted() {},

  methods: {
    // 开始计算
    async startCalc() {
      this.show_store_calc = true
      this.storeData = {
        orderNo: this.$route.params.id,
        prepayPayeeType: 1,
        lockPayeeType: 1,
        depositAmount: '',
        depositPayeeType: 1
      }
      this.calc_result = await calcStoreSettleAPI({ orderNo: this.$route.params.id })
      this.storeData.prepayPayeeType = this.calc_result.prepayPayeeType
      this.storeData.lockPayeeType = this.calc_result.lockPayeeType
      this.storeData.depositPayeeType = this.calc_result.depositPayeeType
      this.storeData.depositAmount = this.calc_result.depositAmount
    },
    // 计算门店结算金额
    async submitCalc() {
      this.calc_result = await calcStoreSettleAPI(this.storeData)
    },
    // 打开修改零售价
    updatePrice() {
      this.priceData.orderNo = this.$route.params.id
      this.priceData.salePrice = this.detail.salePrice
      this.periodDialog = true
    },
    // 保存零售价
    savePrice() {
      updatePriceAPI(this.priceData).then(() => {
        this.$emit('updateDetail')
        this.$message.success('修改成功')
        this.periodDialog = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.base-info-wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .handle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
        &:first-child {
          margin-right: 20px;
          flex: 1;
        }
      }
    }
  }
  .store-box {
    .el-form-item {
      margin-bottom: 0px;
    }
    .row-box {
      display: flex;
      align-items: center;
      & > span {
        margin-right: 10px;
      }
    }
    .calc-money {
      font-size: 22px;
      font-weight: 600;
      color: #f56c6c;
    }
  }
  .store-dialog {
    .el-dialog__body {
      padding-top: 10px;
    }
  }
}
</style>
