<template>
  <!-- 三方 -->
  <div class="wrapper">
    <admin-title title="百融&优分"></admin-title>

    <div class="bairong">
      <div class="bairong-title">百融</div>
      <el-divider></el-divider>
      <div class="content" v-if="baiRong">
        <div class="content-item">
          <div class="content-item-title">债偿压力指数</div>
          <div class="content-item-value warning">{{ baiRong.debtRepayStress || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">反欺诈规则评分</div>
          <div class="content-item-value warning">{{ baiRong.afRuleScore || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近7天申请线上消费分期的次数</div>
          <div class="content-item-value">{{ baiRong.d7CoonAllNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近7天申请线上消费分期的机构数</div>
          <div class="content-item-value">{{ baiRong.d7CoonOrgNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近7天在非银机构-持牌小贷机构申请次数</div>
          <div class="content-item-value">{{ baiRong.d7NbankSloanAllNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近7天在非银机构-持牌小贷机构申请机构数</div>
          <div class="content-item-value">{{ baiRong.d7NbankSloanOrgNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近15天在非银机构-持牌融资租赁机构申请次数</div>
          <div class="content-item-value">{{ baiRong.d15NbankFinleaAllNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近15天在非银机构周末申请机构数"</div>
          <div class="content-item-value">{{ baiRong.d15NbankWeekOrgNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">按身份证号查询，近15天在非银机构申请次数</div>
          <div class="content-item-value">{{ baiRong.d15IdNbankAllNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近1个月申请汽车金融的机构数</div>
          <div class="content-item-value">{{ baiRong.m1AfOrgNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">按身份证号查询，近1个月在非银机构申请次数</div>
          <div class="content-item-value">{{ baiRong.m1IdNbankAllNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">按身份证号查询，近3个月在非银机构申请次数</div>
          <div class="content-item-value">{{ baiRong.m3IdNbankAllNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近6个月在非银机构申请次数</div>
          <div class="content-item-value">{{ baiRong.m6NbankAllNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近6个月在非银机构-其他申请次数</div>
          <div class="content-item-value">{{ baiRong.m6NbankOthAllNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近6个月在非银机构-消费类分期机构申请次数</div>
          <div class="content-item-value">{{ baiRong.m6NbankCfAllNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">按身份证号查询，近6个月在非银机构申请次数</div>
          <div class="content-item-value">{{ baiRong.m6IdNbankAllNum || '暂无数据' }}</div>
        </div>
      </div>
    </div>
    <!-- 优分 -->
    <div class="youfen">
      <div class="youfen-title">优分</div>
      <el-divider></el-divider>
      <div class="content" v-if="youFen">
        <div class="content-item">
          <div class="content-item-title">运营商在网时长</div>
          <div class="content-item-value warning">{{ youFen.netTime || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">信优分</div>
          <div class="content-item-value">{{ youFen.letterScore || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">综合风险验证</div>
          <div class="content-item-value">{{ youFen.riskCheck || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">自然人可信度识别</div>
          <div class="content-item-value warning">{{ youFen.identityCredence || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">疑似养卡</div>
          <div class="content-item-value warning">{{ youFen.suspendCard || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">申请机构总数</div>
          <div class="content-item-value">{{ youFen.applyOrgNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">放款最小金额距今最早天数</div>
          <div class="content-item-value">{{ youFen.minLoanMoneyHistoryDay || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">最早放款距今天数</div>
          <div class="content-item-value">{{ youFen.loanHistoryDay || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">最近逾期距今天数</div>
          <div class="content-item-value">{{ youFen.overdueRecentlyDay || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">最早逾期距今天数</div>
          <div class="content-item-value">{{ youFen.overdueHistoryDay || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">逾期最大金额距今最近天数</div>
          <div class="content-item-value">{{ youFen.overdueMaxMoneyRecentlyDay || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">逾期最大金额距今最早天数</div>
          <div class="content-item-value">{{ youFen.overdueMaxMoneyHistoryDay || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近12月逾期次数</div>
          <div class="content-item-value">{{ youFen.m12OverdueNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">近24月逾期次数</div>
          <div class="content-item-value">{{ youFen.m24OverdueNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">个人涉诉数量</div>
          <div class="content-item-value">{{ youFen.totalInvolvementNum || '暂无数据' }}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">金融黑名单等级</div>
          <div class="content-item-value">{{ youFen.blacklistLevel || '暂无数据' }}</div>
        </div>
      </div>
    </div>
    <div style="height:50px;"></div>
  </div>
</template>

<script>
export default {
  name: 'BaiRong',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    baiRong() {
      return this.info.baiRong || {}
    },
    youFen() {
      return this.info.youFen || {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-divider--horizontal {
    margin: 10px 0 !important;
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
    .my-warning-content {
      color: #f01616;
      font-weight: 600;
      font-size: 18px;
    }
  }
  .title {
    font-weight: 600;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }
  .sub-title {
    margin: 10px 0;
  }
  .bairong,
  .youfen {
    margin-bottom: 20px;
    .bairong-title,
    .youfen-title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    .content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      padding: 10px 0;
      .content-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #93d2f3;
        padding: 15px 30px;
        border-radius: 4px;
        .content-item-title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          text-align: center;
        }
        .content-item-value {
          text-align: center;
        }
      }
    }
  }
  .warning {
    color: #f01616;
    font-weight: 600;
  }
}
</style>
